export const boxShadow = {
  boxShadow:
    "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
};
export const defaultFont = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: "300",
  lineHeight: "1.5em"
};

export const primaryBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)"
};
export const infoBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)"
};
export const successBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)"
};
export const warningBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)"
};
export const errorBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)"
};
export const roseBoxShadow = {
  boxShadow:
    "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)"
};

export const defaultBoxShadow = {
  border: "0",
  borderRadius: "3px",
  boxShadow:
    "0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  padding: "10px 0",
  transition: "all 150ms ease 0s"
};

export const backgroundColor = {
  dark: {
    "color": "rgb(18, 18, 18)",
    "textColor": "rgb(240, 240, 240)"
  }
};

export const primaryColor = {
  normal: {
    dark: {
      background: "rgb(31, 31, 40)",
      textColor: "rgb(240, 240, 240)",
      aHref: {
        hover: {
          backgroundColor: "rgba(240, 240, 240, .5)",
        },
      },
    },
  },
  light: {
    dark: {
      background: "rgb(71, 71, 82)",
      textColor: "rgb(240, 240, 240)",
    },
  },
  dark: {
    dark: {
      background: "rgb(18, 18, 18)",
      textColor: "rgb(170, 170, 170)",
    },
  },
}

export const secondaryColor = {
  normal: {
    "dark": {
      "background": "rgb(239, 69, 57)",
      "textColor": "rgb(227, 227, 227)"
    },
  },
  "light": {
    dark: {
      "background": "rgb(255, 118, 97)",
      "textColor": "rgb(227, 227, 227)"
    }
  },
  "dark": {
    dark: {
      "background": "rgb(179, 0, 15)",
      "textColor": "rgb(227, 227, 227)"
    }
  }
};

export const notificationColor = {
  success: {
    dark: {
      color: "rgb(76, 174, 79)"
    }
  },
  warning: {
    dark: {
      color: "rgb(255, 153, 0)"
    }
  },
  error: {
    dark: {
      color: "rgb(244, 64, 52)"
    }
  },
  info: {
    dark: {
      color: "rgb(0, 172, 193)"
    }
  }
};

export const otherColor = {
  purple: {
    dark: {
      color: "rgb(156, 39, 176)"
    },
  },
  rose: {
    dark: {
      color: "rgb(233, 30, 99)"
    },
  },
  grey: {
    dark: {
      color: "rgb(153, 153, 153)"
    }
  }
};

export const title = {
  margin: "1.75rem 0 0.875rem",
  textDecoration: "none",
  fontWeight: "700",
  fontFamily: defaultFont.fontFamily,
  "&.dark":{
    color: backgroundColor.dark.textColor
  }
};

export const card = {
  dark: {
    cardHeader: {
      primary: {
        color: "#fff",
        background: "linear-gradient(60deg, #ab47bc, #8e24aa)",
        ...primaryBoxShadow
      },
      other: {
        rose: {
          color: "#fff",
          background: "linear-gradient(60deg, #ec407a, #d81b60)",
          ...roseBoxShadow
        }
      },
      warning: {
        color: "#fff",
        background: "linear-gradient(60deg, #ffa726, #fb8c00)",
        ...warningBoxShadow
      },
      success: {
        color: "#fff",
        background: "linear-gradient(60deg, #66bb6a, #43a047)",
        ...successBoxShadow
      },
      error: {
        color: "#fff",
        background: "linear-gradient(60deg, #ef5350, #43a047)",
        ...errorBoxShadow
      },
      info: {
        color: "#fff",
        background: "linear-gradient(60deg, #26c6da, #43a047)",
        ...infoBoxShadow
      }
    },
    general: {
      actions: {
        margin: "0 20px 10px",
        paddingTop: "10px",
        borderTop: "1px solid #eeeeee",
        height: "auto",
        ...defaultFont
      },
      title: {
        ...title,
        marginTop: ".625rem"
      },
      cardLink: {
        marginLeft: "1.25rem"
      },
      cardSubtitle: {
        marginBottom: "0",
        marginTop: "-.375rem"
      }
    }

  }
}

export const drawerWidth = 260;

export const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
};

export const containerFluid = {
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto",
  width: "100%"
};
export const container = {
  ...containerFluid,
  "@media (min-width: 576px)": {
    maxWidth: "540px"
  },
  "@media (min-width: 768px)": {
    maxWidth: "720px"
  },
  "@media (min-width: 992px)": {
    maxWidth: "960px"
  },
  "@media (min-width: 1200px)": {
    maxWidth: "1140px"
  }
};




export default {
  backgroundColor,
  primaryColor,
  secondaryColor,
  notificationColor,
  otherColor,
  card,

  //default 
  drawerWidth,
  transition,
  container,
  containerFluid,
  boxShadow,
  defaultFont,
  primaryBoxShadow,
  infoBoxShadow,
  successBoxShadow,
  warningBoxShadow,
  errorBoxShadow,
  roseBoxShadow,
  defaultBoxShadow,
  title
}