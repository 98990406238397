
const contactPageStyle = {  
  description: {
    color: "#ffffff",
    textAlign: "center"
  },
  textCenter: {
    textAlign: "center"
  },
};

export default contactPageStyle;
