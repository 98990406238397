import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery, Link } from "gatsby"

import GridContainer from "@components/Grid/GridContainer.js"
import GridItem from "@components/Grid/GridItem.js"

import { BlogList } from "@components/layout/blog/widget/blogList.js"

BlogMysticApe.propTypes = {
  props: PropTypes.object,
}
function HasPosts({ props }) {
  if (props.posts.edges.length < 1) {
    return false
  }

  return true
}
function DisplayNoNodeMessage({ props, mysticApe, postGroupValue }) {
  if (
    HasPosts({ props }) ||
    postGroupValue === null ||
    typeof postGroupValue === "undefined"
  ) {
    return null
  }
  if (postGroupValue.length < 1) {
    return null
  }
  return (
    <GridItem cs={12} sm={12} md={12}>
      <div>
        Could not find any posts by {mysticApe}. Please select another Mystic
        Ape.
      </div>
    </GridItem>
  )
}
export default function BlogMysticApe({
  children,
  pageNode,
  location,
  ...props
}) {
  const postGroup = "mysticApe"

  const postGroupValue =
    props.data === null || typeof props.data === "undefined" ? "" : props.data
  const postDynamicTitle =
    props.dynamicTitle === null || typeof props.dynamicTitle === "undefined"
      ? ""
      : props.dynamicTitle

  const { sectionNodes } = useStaticQuery(
    graphql`
      query {
        sectionNodes: allThreeMysticApesMysticApe {
          edges {
            node {
              name
              monkey
              slug
            }
          }
        }
      }
    `
  )

  if (postGroupValue === "" || !HasPosts({ props })) {
    return (
      <GridContainer justify="center">
        {DisplayNoNodeMessage({ props, postDynamicTitle, postGroupValue })}
        {sectionNodes.edges.map(({ node }, index) => (
          <GridItem cs={12} sm={6} md={4} key={index}>
            <Link
              to={`/blog/${postGroup.toLowerCase()}/${node.slug}`}
              title={`${node.name} - ${node.monkey}`}
              aria-label={`${node.name} - ${node.monkey}`}
            >
              <div>{`${node.name} - ${node.monkey}`}</div>
            </Link>
          </GridItem>
        ))}
      </GridContainer>
    )
  }

  return (
    <BlogList
      children={children}
      pageNode={pageNode}
      location={location}
      postGroupValue={postGroupValue}
      postDynamicTitle={postDynamicTitle}
      {...props}
    />
  )
}
