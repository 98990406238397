import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import classNames from "classnames"

import { H3, H6 } from "@components/header/header.js"
import GridContainer from "@components/Grid/GridContainer.js"
import GridItem from "@components/Grid/GridItem.js"

import GridList from "@material-ui/core/GridList"
import GridListTile from "@material-ui/core/GridListTile"
import GridListTileBar from "@material-ui/core/GridListTileBar"

import ThreeMysticApesUtil from "three_mystic_apes_extension/util/utilities.js"
import ThreeMysticApesThemeHelper from "three_mystic_apes_extension/util/themeHelper.js"

BlogList.propTypes = {
  props: PropTypes.object,
}

export default {
  BlogList,
}

export function BlogList({ children, pageNode, location, postGroup, postGroupValue, postDynamicTitle, ...props }) {

  const themeVariation = ThreeMysticApesThemeHelper.GetThemeVariation()

  const { className } = props


  const postListGrid = "postListGrid"

  const postsGridListClassNames = classNames({
    [postListGrid]: true,
    [themeVariation]: true,
    [className]: className,
  })

  if (props.posts === null || typeof props.posts === 'undefined'){
    return null;
  }
    //Check out mrexcel.com
    return (
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={12}>
          <GridList cellHeight={180}>
            {props.posts.edges.map(({ node }, index) => (
              <GridListTile key={index} className={postsGridListClassNames}>
                <Link
                  to={node.slug}
                  title={node.post.title}
                  aria-label={node.post.ariaLabel}
                  state={{
                    crum: {
                      pageNode: pageNode,
                      locationSlug: ThreeMysticApesUtil.TrimURLPath(
                        location.pathname
                      ),
                      postGroupValue,
                      postDynamicTitle,
                    },
                  }}
                >
                  <img src={node.post.hero.publicURL} alt={node.post.title} />
                  <GridListTileBar
                    title={<H3 title={node.post.title}>{node.post.title}</H3>}
                    subtitle={
                      <div>
                        <div>
                          {ThreeMysticApesUtil.FormatShortDate(
                            ThreeMysticApesUtil.GetDateTime(node.post.startDate)
                          )}
                        </div>
                        <H6 title={`${node.post.excerpt.substring(0, 80)}...`}>
                          {node.post.excerpt}
                        </H6>
                      </div>
                    }
                  />
                </Link>
              </GridListTile>
            ))}
          </GridList>
        </GridItem>
      </GridContainer>
    )
}
