import React from "react"
import PropTypes from "prop-types";
import { graphql, useStaticQuery, Link } from "gatsby"

import GridContainer from "@components/Grid/GridContainer.js";
import GridItem from "@components/Grid/GridItem.js";

import ThreeMysticApesUtil from "three_mystic_apes_extension/util/utilities.js"

import { BlogList } from "@components/layout/blog/widget/blogList.js"


BlogTag.propTypes = {
    props: PropTypes.object,
}


export default function BlogTag({ children, pageNode, location, ...props }) {
    const postGroup = "tag";

    const postGroupValue =
      props.data === null || typeof props.data === "undefined" ? "" : props.data

    const { distinctList } = useStaticQuery(
        graphql`
                query {
                    distinctList: allThreeMysticApesPost(filter: {showNode: {eq: true}}) {
                        list: distinct(field: post___tags)
                    }                    
                }
            `)
    
    if (postGroupValue === "") {

        return (
            <GridContainer justify="center">
                {distinctList.list.map((item, index) => (
                    <GridItem cs={12} sm={6} md={4} key={index}>
                        <Link
                            to={`/blog/${postGroup}/${ThreeMysticApesUtil.Slugify(item).toLowerCase()}`}
                            title={item}
                            aria-label={item}
                        >
                            <div>
                                {item}
                            </div>
                        </Link>
                    </GridItem>
                ))}
            </GridContainer>
        )

    }

    return (
      <BlogList
        children={children}
        pageNode={pageNode}
        location={location}
        postGroup={postGroup}
        postGroupValue={postGroupValue}
        {...props}
      />
    )
}