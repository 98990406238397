import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"



GoogleRecaptcha.propTypes = {
  props: PropTypes.object,
}



export default function GoogleRecaptcha(props) {
    const { site } = useStaticQuery(
      graphql`
        query {
          site {
            siteMetadata {
              componentKeys {
                google {
                  recaptcha
                }
              }
            }
          }
        }
      `
    )
    const recaptchaKey = site.siteMetadata.componentKeys.google.recaptcha
  return (
      <Helmet>
        <script>
          {`
          if(window){
            if(!window.grecaptcha){
              (function(w,d,s){var f=d.getElementsByTagName(s)[0];j=d.createElement(s);j.async=true;j.src='https://www.google.com/recaptcha/api.js?render=${recaptchaKey}';f.parentNode.insertBefore(j,f);})(window,document,'script');
            }
          }          
          `}
        </script>
      </Helmet>
  )
}

