/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";


import styles from "assets/jss/components/header/style.js";
import ThreeMysticApesThemeHelper from "three_mystic_apes_extension/util/themeHelper.js";

const useStyles = makeStyles(styles);

export function H1(props) {
  const themeVariation = ThreeMysticApesThemeHelper.GetThemeVariation();
  const classes = useStyles();
  const { className, ...rest } = props;

  const headerClasses = classNames({
    [classes.hdr]: true,
    [`h1`]:true,
    [themeVariation]: true,
    [className]: className !== undefined
  });

  function GetContent(){
    if(props.children === null || typeof children === 'undefined'){
      return props.title;
    }

    return props.children
  }

  return (
    <h1 className={headerClasses} title={props.title} aria-label={props.title}>
      {GetContent()}
    </h1>
  )
}

export function H2(props) {
  const themeVariation = ThreeMysticApesThemeHelper.GetThemeVariation();
  const classes = useStyles();
  const { className, children, ...rest } = props;

  const headerClasses = classNames({
    [classes.hdr]: true,
    [`h2`]: true,
    [themeVariation]: true,
    [className]: className !== undefined
  });

  function GetContent(){
    if(props.children === null || typeof children === 'undefined'){
      return props.title;
    }

    return props.children
  }
 
  return (
    <h2 className={headerClasses} title={props.title} aria-label={props.title}>
      {GetContent()}
    </h2>
  )
}

export function H3(props) {
  const themeVariation = ThreeMysticApesThemeHelper.GetThemeVariation();
  const classes = useStyles();
  const { className, children, ...rest } = props;

  const headerClasses = classNames({
    [classes.hdr]: true,
    [`h3`]: true,
    [themeVariation]: true,
    [className]: className !== undefined
  });

  function GetContent(){
    if(props.children === null || typeof children === 'undefined'){
      return props.title;
    }

    return props.children
  }
  return (
    <h3 className={headerClasses} title={props.title} aria-label={props.title}>
      {GetContent()}</h3>
  );
}

export function H4(props) {
  const themeVariation = ThreeMysticApesThemeHelper.GetThemeVariation();
  const classes = useStyles();
  const { className, children, ...rest } = props;
  
  const headerClasses = classNames({
    [classes.hdr]: true,
    [`h4`]: true,
    [themeVariation]: true,
    [className]: className !== undefined
  });

  function GetContent(){
    if(props.children === null || typeof children === 'undefined'){
      return props.title;
    }

    return props.children
  }
  return (
    <h4 className={headerClasses} title={props.title} aria-label={props.title}>
      {GetContent()}
    </h4>
  )
}

export function H5(props) {
  const themeVariation = ThreeMysticApesThemeHelper.GetThemeVariation();
  const classes = useStyles();
  const { className, children, ...rest } = props;

  const headerClasses = classNames({
    [classes.hdr]: true,
    [`h5`]: true,
    [themeVariation]: true,
    [className]: className !== undefined
  });

  function GetContent(){
    if(props.children === null || typeof children === 'undefined'){
      return props.title;
    }

    return props.children
  }

  return (
    <h5 className={headerClasses} title={props.title} aria-label={props.title}>
      {GetContent()}
    </h5>
  )
}

export function H6(props) {
  const themeVariation = ThreeMysticApesThemeHelper.GetThemeVariation();
  const classes = useStyles();
  const { className, children, ...rest } = props;

  const headerClasses = classNames({
    [classes.hdr]: true,
    [`h6`]: true,
    [themeVariation]: true,
    [className]: className !== undefined
  });

  function GetContent(){
    if(props.children === null || typeof children === 'undefined'){
      return props.title;
    }

    return props.children
  }

  return (
    <h6 className={headerClasses} title={props.title} aria-label={props.title}>
      {GetContent()}
    </h6>
  )
}

export default {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
}