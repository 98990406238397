import React from "react"
import axios from "axios"
import { Helmet } from "react-helmet"

// nodejs library that concatenates classes
import classNames from "classnames"
// nodejs library to set properties for components
import PropTypes from "prop-types"

import { useStaticQuery, graphql } from "gatsby"
import ThreeMysticApesUtil from "three_mystic_apes_extension/util/utilities.js"

// core components
import GoogleRecaptcha from "@components/layout/section/common/page/recaptcha.js"
import { H4 } from "@components/header/header.js"
import GridContainer from "@components/Grid/GridContainer.js"
import GridItem from "@components/Grid/GridItem.js"
import TextField from "@material-ui/core/TextField"
import Button from "@components/customButtons/button.js"
import Card from "@components/card/card.js"
import CardBody from "@components/card/cardBody.js"
import CardFooter from "@components/card/cardFooter.js"
import CardHeader from "@components/card/cardHeader.js"

import ThreeMysticApesThemeHelper from "three_mystic_apes_extension/util/themeHelper.js"

import { useSnackbar } from "notistack"

ContactSection.propTypes = {
  props: PropTypes.object,
}

export default {
  ContactSection,
}

export function ContactSection(props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            componentKeys {
              google {
                recaptcha
              }
              getform {
                domain
                contact
              }
            }
          }
        }
      }
    `
  )

  const { classesPage } = props

  const recaptchaKey = site.siteMetadata.componentKeys.google.recaptcha
  const getFormDomain = site.siteMetadata.componentKeys.getform.domain
  const getFormKey = site.siteMetadata.componentKeys.getform.contact
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const themeVariation = ThreeMysticApesThemeHelper.GetThemeVariation();

  const contactFormData = {
    fname: ThreeMysticApesUtil.GeneralFormObject(
      "Please provide your first name",
      3,
      100
    ),
    lname: ThreeMysticApesUtil.GeneralFormObject(
      "Please provide your last name",
      3,
      100
    ),
    email: ThreeMysticApesUtil.GeneralFormObject(
      "Please provide your email",
      0,
      255,
      {
        data: { presence: { allowEmpty: false }, email: true },
      }
    ),
    subject: ThreeMysticApesUtil.GeneralFormObject(
      "Please provide the subject of the message",
      5,
      80
    ),
    message: ThreeMysticApesUtil.GeneralFormObject(
      "Please provide your message ",
      25,
      500
    ),
    submit: {
      attribute: {
        disabled: ThreeMysticApesUtil.CreateStateData(false),
        text: ThreeMysticApesUtil.CreateStateData("Send Message"),
      },
    },
    reset: function () {
      const keys = Object.keys(this)
      for (let index = 0; index < keys.length; index++) {
        if (
          this[keys[index]].validate === null ||
          typeof this[keys[index]].validate === "undefined" ||
          this[keys[index]].validate === undefined
        ) {
          continue
        }

        this[keys[index]].value.setData("")
        this[keys[index]].value.data = ""
      }
    },
    validate: function () {
      const keys = Object.keys(this)
      let validateObject = true
      for (let index = 0; index < keys.length; index++) {
        if (
          this[keys[index]].validate === null ||
          typeof this[keys[index]].validate === "undefined" ||
          this[keys[index]].validate === undefined
        ) {
          continue
        }

        if (this[keys[index]].validate() === false) {
          validateObject = false
          continue
        }
      }

      return validateObject
    },
  }

  function processInputChange(e, key) {
    contactFormData[key].value.setData(e.target.value)
    contactFormData[key].value.data = e.target.value

    if (contactFormData[key].props.error.data) {
      contactFormData[key].validate()
    }
  }

  function processCaptchaClick(e, data) {
    if (e) {
      contactFormData.submit.attribute.text.setData("loading...")
      contactFormData.submit.attribute.disabled.setData(true)
      e.preventDefault()

      data = new FormData()
      data.append(
        "name",
        contactFormData.fname.value.data +
          " " +
          contactFormData.lname.value.data
      )
      data.append("email", contactFormData.email.value.data)
      data.append(
        "message",
        "Email Subject is:" +
          contactFormData.subject.value.data +
          "\r\n" +
          "\r\n" +
          contactFormData.message.value.data
      )
      data.append("g-recaptcha-response", "")
    }

    const dataIsValid = contactFormData.validate()
    if (!dataIsValid) {
      contactFormData.submit.attribute.text.setData("Send Message")
      contactFormData.submit.attribute.disabled.setData(false)
      return
    }

    if (window) {
      const grecaptcha = window.grecaptcha
      if (grecaptcha == null) {
        setTimeout(function () {
          processCaptchaClick(null, data)
        }, 100)
        return
      }

      grecaptcha.ready(function () {
        grecaptcha
          .execute(recaptchaKey, { action: "submit" })
          .then(function (token) {
            data.set("g-recaptcha-response", token)

            const action = key => (
              <Button
                role="button"
                onClick={() => {
                  closeSnackbar(key)
                }}
              >
                'Dismiss'
              </Button>
            )

            axios({
              method: "post",
              url: getFormDomain + getFormKey,
              data: data,
            })
              .then(r => {
                contactFormData.submit.attribute.text.setData("Send Message")
                contactFormData.submit.attribute.disabled.setData(false)
                contactFormData.reset()
                enqueueSnackbar("Message Sent", {
                  variant: "success",
                  preventDuplicate: true,
                  autoHideDuration: 3000,
                  action,
                })
              })
              .catch(r => {
                contactFormData.submit.attribute.text.setData("Send Message")
                contactFormData.submit.attribute.disabled.setData(false)
                enqueueSnackbar(
                  "Message could not be sent, please try again.",
                  {
                    variant: "error",
                    preventDuplicate: true,
                    autoHideDuration: 3000,
                    action,
                  }
                )
              })
          })
      })
    }
  }

  return (
    <form id="contactForm" method="POST">
      <Helmet>
        <script>
          {`
          if(window){
            if(!window.grecaptcha){
              (function(w,d,s){var f=d.getElementsByTagName(s)[0];j=d.createElement(s);j.async=true;j.src='https://www.google.com/recaptcha/api.js?render=${recaptchaKey}';f.parentNode.insertBefore(j,f);})(window,document,'script');
            }
          }          
          `}
        </script>
      </Helmet>
      <GoogleRecaptcha />
      <Card className={classNames(classesPage.card, themeVariation)}>
        <CardHeader color="primary">
          <H4 title="Contact Us" className="cardTitle">
            Contact Us
          </H4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <TextField
                label="First Name*"
                inputProps={{ "aria-label": "First Name - Required" }}
                id="contactFormFirstName"
                fullWidth={true}
                variant="filled"
                error={contactFormData.fname.props.error.data}
                onChange={evt => processInputChange(evt, "fname")}
                helperText={contactFormData.fname.props.helperText.data}
                className="contactText"
                value={contactFormData.fname.value.data}
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <TextField
                label="Last Name*"
                inputProps={{ "aria-label": "Last Name - Required" }}
                id="contactFormLastName"
                fullWidth={true}
                variant="filled"
                error={contactFormData.lname.props.error.data}
                onChange={evt => processInputChange(evt, "lname")}
                helperText={contactFormData.lname.props.helperText.data}
                className="contactText"
                value={contactFormData.lname.value.data}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                label="Email*"
                inputProps={{ "aria-label": "Email - Required" }}
                id="contactFormEmail"
                fullWidth={true}
                variant="filled"
                error={contactFormData.email.props.error.data}
                onChange={evt => processInputChange(evt, "email")}
                helperText={contactFormData.email.props.helperText.data}
                className="contactText"
                value={contactFormData.email.value.data}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                label="Subject*"
                inputProps={{ "aria-label": "Subject - Required" }}
                id="contactFormSubject"
                fullWidth={true}
                variant="filled"
                error={contactFormData.subject.props.error.data}
                onChange={evt => processInputChange(evt, "subject")}
                helperText={contactFormData.subject.props.helperText.data}
                className="contactText"
                value={contactFormData.subject.value.data}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                label="Message*"
                inputProps={{ "aria-label": "Message - Required" }}
                id="contactFormMessage"
                fullWidth={true}
                multiline={true}
                rows={5}
                variant="filled"
                error={contactFormData.message.props.error.data}
                onChange={evt => processInputChange(evt, "message")}
                helperText={contactFormData.message.props.helperText.data}
                className="contactText"
                value={contactFormData.message.value.data}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
        <CardFooter className={classNames([classesPage.CardFooter])}>
          <Button
            color="primary"
            onClick={processCaptchaClick}
            aria-label="Contact Form Submit"
            disabled={contactFormData.submit.attribute.disabled.data}
            className={classNames("SendButtonJustifyRight")}
          >
            {contactFormData.submit.attribute.text.data}
          </Button>
        </CardFooter>
      </Card>
    </form>
  )
}
