import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Accordion from '@material-ui/core/Accordion';

import makeStyles from "@material-ui/core/styles/makeStyles";
import accordionStyle from "assets/jss/components/accordion/accordionStyle.js";
import ThreeMysticApesThemeHelper from "three_mystic_apes_extension/util/themeHelper.js";

const makeComponentStyles = makeStyles(() => ({
    ...accordionStyle
}));

export function CustomAccordion(props) {
    const { 
        children,
        themeColorVariation,
        className,
        ...accordionAttributes 
    } = props;
    const themeVariation = ThreeMysticApesThemeHelper.GetThemeVariation();

    const classes = makeComponentStyles();

    const accordionClassNames = classNames({
        [classes.accordion]: true,
        [themeVariation]: true,
        [themeColorVariation]: themeColorVariation,
        [className]: className
    });


    return (
        <Accordion className={accordionClassNames} {...accordionAttributes}>
            {children}
        </Accordion>
    )
}

CustomAccordion.propTypes = {
    className: PropTypes.string,
    themeColorVariation: PropTypes.string,
    children: PropTypes.node
};