import {
  card
} from "assets/jss/common/threemysticapes.js";

export const cardHeaderStyle = {
  cardHeader: {
    borderRadius: "3px",
    padding: "1rem 15px",
    marginLeft: "15px",
    marginRight: "15px",
    marginTop: "-30px",
    border: "0",
    marginBottom: "0"
  },
  cardHeaderPlain: {
    marginLeft: "0px",
    marginRight: "0px"
  },
  warningCardHeader:{
    "&.dark":{
      ...card.dark.cardHeader.warning
    }
  },
  successCardHeader: {
    "&.dark": {
      ...card.dark.cardHeader.success
    }
  },
  errorCardHeader: {
    "&.dark": {
      ...card.dark.cardHeader.error
    }
  },
  infoCardHeader: {
    "&.dark": {
      ...card.dark.cardHeader.info
    }
  },
  primaryCardHeader: {
    "&.dark": {
      ...card.dark.cardHeader.primary
    }
  }
};

export default cardHeaderStyle;
