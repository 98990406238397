import React from "react";
import { useStaticQuery, graphql } from "gatsby"

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import GridContainer from "@components/Grid/GridContainer.js";
import GridItem from "@components/Grid/GridItem.js";


export function AccreditationList(props) {
    const { accreditation } = useStaticQuery(
        graphql`
            query {
                accreditation:allThreeMysticApesAccreditation {
                    edges {
                        node {
                        id
                        accreditation {
                            created
                            dateEarned
                            endDate
                            dateExpires
                            issuer
                            name
                            startDate  
                            image {
                                publicURL
                                }              
                            }
                        }
                    }
                }
            }
        `
    )

    const { useStyles } = props;

    const classes = useStyles();

    return (
        <GridContainer justify="center">
            <GridItem cs={12} sm={12} md={12}>
                <GridList cellHeight={180} className={classes.gridList}>
                    {accreditation.edges.map(({ node }, index) => (
                        <GridListTile key={index}>
                            <img src={node.accreditation.image.publicURL} alt={node.accreditation.name} />
                            <GridListTileBar
                                title={node.accreditation.name}
                                subtitle={<span>earned: {node.accreditation.dateEarned}</span>}
                            />
                        </GridListTile>
                    ))}
                </GridList>
            </GridItem>
        </GridContainer>
    )
};