module.exports = {
  GetThemeVariation: function () {
    let themeVariation =
      typeof window !== "undefined"
        ? localStorage.getItem("themeVariation")
          ? localStorage.getItem("themeVariation")
          : null
        : null

    if (themeVariation !== null) {
      return themeVariation
    }

    themeVariation = "dark"
    if (typeof window !== "undefined") {
      localStorage.setItem("themeVariation", themeVariation)
    }

    return themeVariation
  },
  CookiePolicyUserDisplay: function (cookiePolicyUserDisplayValue) {
    let cookiePolicy = null
    if (
      cookiePolicyUserDisplayValue === null ||
      typeof cookiePolicyUserDisplayValue === "undefined"
    ) {
      cookiePolicyUserDisplayValue = false
      cookiePolicy =
        typeof window !== "undefined"
          ? localStorage.getItem("cookiePolicyDisplay")
            ? localStorage.getItem("cookiePolicyDisplay")
            : null
          : null

      if (cookiePolicy !== null && typeof cookiePolicy !== "undefined") {
        return parseInt(cookiePolicy) === 1
      }
    }

    cookiePolicy = cookiePolicyUserDisplayValue === true ? 1 : 0
    if (typeof window !== "undefined") {
      localStorage.setItem("cookiePolicyDisplay", cookiePolicy)
    }

    return cookiePolicy
  },
}
