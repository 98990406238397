import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useQueryParam, StringParam } from "use-query-params"
import { Helmet } from "react-helmet"

export function SearchResults(props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            componentKeys {
              google {
                customSearch {
                  internal
                  external
                }
              }
            }
          }
        }
      }
    `
  )

  const [searchType, setSearchType] = useQueryParam("searchType", StringParam)
  function GetSearchType() {
    if (searchType === null || (typeof searchType).toLowerCase() !== "string") {
      setSearchType("external")
      return "external"
    }

    if (searchType.toLowerCase() === "internal") {
      return "internal"
    }

    setSearchType("external")
    return "external"
  }

  const [searchText] = useQueryParam("q", StringParam)
  function HasSearchText() {
    if (searchText === null || (typeof searchText).toLowerCase() !== "string") {
      return false
    }

    return searchText.trim().length > 0
  }

  if (!HasSearchText()) {
    return (
      <Fragment>
        <div></div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Helmet>
        <script
          async
          src={`https://cse.google.com/cse.js?cx=${
            site.siteMetadata.componentKeys.google.customSearch[GetSearchType()]
          }`}
        />
      </Helmet>
      <div className={`gcse-searchresults-only`}></div>
    </Fragment>
  )
}
