import React from "react";

import { CustomAccordion } from "@components/accordion/customAccordion.js";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';

import GridContainer from "@components/Grid/GridContainer.js";
import GridItem from "@components/Grid/GridItem.js";

import { H3 } from "@components/header/header.js";

import { AccreditationList } from "@components/layout/section/about/accreditationList.js";


export function AccordionData(props) {
    const { useStyles } = props;
    const classes = useStyles();

    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={12}>
          <CustomAccordion
            themeColorVariation="light"
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="about-why-content"
              id="about-why-header"
            >
              <H3 title="Why the site">Why the site</H3>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <div>
                I have made a lofty goal of looking at ways I can become a
                Microsoft MVP and even a longer term would be to also look at
                how to become a Google Developers Expert. This is how I am
                choosing to make my presense and start to support the community.
                I have a hope that this site will also help me work on some of
                my soft skills like writting, and possibly speaking. I chose to
                use the branding of 3 Mystic Apes because I have a few ideas of
                how to have fun with it.
                <br />
                Along those goals I am hoping this site will help me improve my
                tech skills along with some soft skills.
                <br />
                <div>
                  To Learn more about what it means to be part of those programs
                  you can learn more at:
                  <div>
                    <a
                      href="https://mvp.microsoft.com/en-us/"
                      target="blank"
                      title="Microsoft MVP"
                      aria-label="Microsoft MVP"
                    >
                      Microsoft MVP
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://rd.microsoft.com/en-us/"
                      target="blank"
                      title="Microsoft Regional Director"
                      aria-label="Microsoft Regional Director"
                    >
                      Microsoft Regional Director
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://developers.google.com/community/experts"
                      target="blank"
                      title="Goolge Experts"
                      aria-label="Google Experts"
                    >
                      Google Experts
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://aws.amazon.com/developer/community/heroes/"
                      target="blank"
                      title="AWS Hero"
                      aria-label="AWS Hero"
                    >
                      AWS Hero
                    </a>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </CustomAccordion>
          <CustomAccordion
            themeColorVariation="light"
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="about-why-content"
              id="about-why-header"
            >
              <H3 title="Expert Generalist">Expert Generalist</H3>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <div>
                I am an expert generalist is technology is my shiny. If it’s
                technology-related I am probably interested in it. If I haven’t
                looked into it, once I learn about it I will probably at least
                read some about it and watch some YouTube on it. I have helped
                start 2 companies. The one that did the best was a school
                uniform company. I was the tech person. We bought
                AspDotNetStorefront (not sponsored), a version with source code,
                and I modified it to meet the needs of the site. Looking back
                there are some changes I would have made to better integrate but
                it did its job. The second company unfortunately never left
                alpha. I leave the site up as a reminder, junglebars.com. There
                are a lot of good memories, a lot of sad memories, but a lot of
                lessons learned. I am proud of what was created, just sad of
                never launching it officially. If you view it please just keep
                in mind that version was created back in 2009. If you want to
                force yourself to learn certain things, find someone, and try to
                start a company. I learned more in 6 months trying to start a
                company fresh out of college then I did in college. It can be a
                very enlightening experience.
                <br />
                <br />
                The two things I would say for someone fresh out of college. Try
                to start a company, and find a mentor early. A good mentor can
                be priceless.
              </div>
            </AccordionDetails>
          </CustomAccordion>
          <CustomAccordion
            themeColorVariation="light"
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="about-why-content"
              id="about-why-header"
            >
              <H3 title="My Background">My Background</H3>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <div>
                As I mentioned I helped start 2 companies. I have worked for 3
                different hosting companies, working on the phones for 2 of them
                and being a Sr .NET developer for one of them. While working at
                one of them I managed to move up off the phones. I had access to
                over 700 Linux servers and over 200 Windows servers. I helped
                manage professional services, the office of the president
                emails, I wrote a script that would fix an issue with PHP
                applications to help them migrate from MySQL 4.x to 5.x, and
                among other things a low-end sysadmin. I was the person that
                they would say this customer needs this done, and I would find a
                way to do it. It was a fun job I got to dabble in a lot of
                things. I got to be the SR/Lead developer for a local web
                development company. One of the best opportunities is when I got
                on as a .NET Developer for a digital consultancy (Nerdery). I
                started as a developer worked hard and did a pivot. I
                transitioned to the cloud and start a new path. I look forward
                to seeing where I go from here.
              </div>
            </AccordionDetails>
          </CustomAccordion>
          <CustomAccordion
            themeColorVariation="light"
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="about-why-content"
              id="about-why-header"
            >
              <H3 title="Accreditations">Accreditations</H3>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <GridContainer justify="center">
                <GridItem cs={4} sm={4} md={4}>
                  <div>
                    To view all current accreditations you can goto the
                    following links:
                    <div>
                      <a
                        href="https://www.youracclaim.com/users/ron-truex/badges"
                        target="blank"
                        title="Acclaim (AWS/Microsoft)"
                        aria-label="Acclaim (AWS/Microsoft)"
                      >
                        Acclaim (AWS/Microsoft)
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://www.credential.net/profile/rontruex/wallet"
                        target="blank"
                        title="Credential.net (GCP)"
                        aria-label="Credential.net (GCP)"
                      >
                        Credential.net (GCP)
                      </a>
                    </div>
                  </div>
                </GridItem>
                <GridItem cs={2} sm={2} md={2}>
                  &nbsp;
                </GridItem>
                <GridItem cs={6} sm={6} md={6}>
                  <div>
                    <AccreditationList useStyles={useStyles} />
                  </div>
                </GridItem>
              </GridContainer>
            </AccordionDetails>
          </CustomAccordion>
          <CustomAccordion
            themeColorVariation="light"
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="other-thank-you"
              id="other-thank-you"
            >
              <H3 title="More Thanks">More Thank Yous</H3>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <GridContainer justify="center">
                <GridItem cs={4} sm={4} md={4}>
                  <div>
                    <div>
                      <a
                        href="https://www.nerdery.com"
                        target="blank"
                        title="Nerdery"
                        aria-label="Nerdery"
                      >
                        Nerdery
                      </a>
                    </div>
                    <div>
                      They give me the oppertunity to work with some incredibly
                      talented people, and lots of options to grow with people
                      willing to help.
                    </div>
                  </div>
                </GridItem>
                <GridItem cs={4} sm={4} md={4}>
                  <div>
                    <div>
                      <a
                        href="https://github.com"
                        target="blank"
                        title="GitHub"
                        aria-label="GitHub"
                      >
                        GitHub
                      </a>
                    </div>
                    <div>
                      For having such an awsome system and doing so much for
                      free.
                    </div>
                  </div>
                </GridItem>
                <GridItem cs={4} sm={4} md={4}>
                  <div>
                    <div>
                      <a
                        href="https://cloud.google.com/"
                        target="blank"
                        title="GCP - Google Cloud Platform"
                        aria-label="GCP - Google Cloud Platform"
                      >
                        GCP
                      </a>
                    </div>
                    <div>
                      For having such an awsome system and doing so much for
                      free.
                    </div>
                  </div>
                </GridItem>
                <GridItem cs={4} sm={4} md={4}>
                  <div>
                    <div>
                      <a
                        href="https://azure.com"
                        target="blank"
                        title="Azure (Microsoft)"
                        aria-label="Azure (Microsoft)"
                      >
                        Azure (Microsoft)
                      </a>
                    </div>
                    <div>With out you I would be doing something else</div>
                  </div>
                </GridItem>
                <GridItem cs={4} sm={4} md={4}>
                  <div>
                    <div>
                      <a
                        href="https://creative-tim.com"
                        target="blank"
                        title="Creative Tim"
                        aria-label="Creative Tim"
                      >
                        Creative Tim
                      </a>
                    </div>
                    <div>
                      I was able to use one of their free templates to get me
                      started.
                    </div>
                  </div>
                </GridItem>
                <GridItem cs={4} sm={4} md={4}>
                  <div>
                    <div>
                      <a
                        href="https://www.gatsbyjs.com"
                        target="blank"
                        title="Gatsby Static Site Generator"
                        aria-label="Gatsby Static Site Generator"
                      >
                        Gatsby
                      </a>
                    </div>
                    <div>I used them to generate the site.</div>
                  </div>
                </GridItem>
                <GridItem cs={4} sm={4} md={4}>
                  <div>
                    <div>
                      <a
                        href="https://getform.io"
                        target="blank"
                        title="GetForm.io"
                        aria-label="GetForm.io"
                      >
                        GetForm.io
                      </a>
                    </div>
                    <div>
                      They have a great service for adding forms on static sites
                      like this.
                    </div>
                  </div>
                </GridItem>
                <GridItem cs={4} sm={4} md={4}>
                  <div>
                    <div>
                      <a
                        href="https://staticman.net"
                        target="blank"
                        title="Staticman"
                        aria-label="Staticman"
                      >
                        Staticman
                      </a>
                    </div>
                    <div>
                      They have a great service for allowing comments to be
                      added to static sites. (work in progress)
                    </div>
                  </div>
                </GridItem>
                <GridItem cs={4} sm={4} md={4}>
                  <div>
                    <div>
                      <a
                        href="https://unsplash.com"
                        target="blank"
                        title="Unsplash"
                        aria-label="Unsplash"
                      >
                        Unsplash
                      </a>
                    </div>
                    <div>For having royalty free images.</div>
                  </div>
                </GridItem>
                <GridItem cs={4} sm={4} md={4}>
                  <div>
                    <div>
                      <a
                        href="https://pixabay.com"
                        target="blank"
                        title="pixabay"
                        aria-label="pixabay"
                      >
                        pixabay
                      </a>
                    </div>
                    <div>For having royalty free images.</div>
                  </div>
                </GridItem>
              </GridContainer>
            </AccordionDetails>
          </CustomAccordion>
        </GridItem>
      </GridContainer>
    )
};