import { defaultFont, primaryColor } from "assets/jss/common/threemysticapes.js";

const headerStyle = {
  "hdr": {
    ...defaultFont,
    display: "block",
    fontWeight: 700,
    marginLeft: 0,
    marginRight: 0,
    "&.dark": {
      color: primaryColor.normal.dark.textColor
    },
    "&.h1": {
      fontSize: "2rem",
      marginTop: ".33rem",
      marginBottom: ".33rem"
    },
    "&.h2": {
      fontSize: "1.5rem",
      marginTop: ".33rem",
      marginBottom: ".33rem"
    },
    "&.h3": {
      fontSize: "1.33rem",
      marginTop: ".33rem",
      marginBottom: ".33rem"
    },
    "&.h4": {
      fontSize: "1.17rem",
      marginTop: ".33rem",
      marginBottom: ".33rem"
    },
    "&.h5": {
      fontSize: "0.83rem",
      marginTop: ".33rem",
      marginBottom: ".33rem"
    },
    "&.h6": {
      fontSize: ".75rem",
      marginTop: ".33rem",
      marginBottom: ".33rem"
    }
  }
};
export default headerStyle;
