import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery, Link } from "gatsby"

import GridContainer from "@components/Grid/GridContainer.js"
import GridItem from "@components/Grid/GridItem.js"

import { BlogList } from "@components/layout/blog/widget/blogList.js"

BlogSeries.propTypes = {
  props: PropTypes.object,
}

export default function BlogSeries({ children, pageNode, location, ...props }) {
  const postGroup = "series"

  const postGroupValue =
    props.data === null || typeof props.data === "undefined" ? "" : props.data
  const postDynamicTitle =
    props.dynamicTitle === null || typeof props.dynamicTitle === "undefined"
      ? ""
      : props.dynamicTitle

  const { sectionNodes } = useStaticQuery(
    graphql`
      query {
        sectionNodes: allThreeMysticApesPostSeries {
          edges {
            node {
              name
              slug
              display
            }
          }
        }
      }
    `
  )

  if (postGroupValue === "") {
    return (
      <GridContainer justify="center">
        {sectionNodes.edges.map(({ node }, index) => (
          <GridItem cs={12} sm={6} md={4} key={index}>
            <Link
              to={`/blog/${postGroup.toLowerCase()}/${node.slug}`}
              title={node.display}
              aria-label={node.display}
            >
              <div>{node.display}</div>
            </Link>
          </GridItem>
        ))}
      </GridContainer>
    )
  }

  return (
    <BlogList
      children={children}
      pageNode={pageNode}
      location={location}
      postGroupValue={postGroupValue}
      postDynamicTitle={postDynamicTitle}
      {...props}
    />
  )
}
