import React, { Fragment } from "react"
import PropTypes from "prop-types"

import { BlogList } from "@components/layout/blog/widget/blogList.js"

BlogSection.propTypes = {
  props: PropTypes.object,
}

export default {
  BlogSection,
}

export function BlogSection({ children, pageNode, location, posts, ...props }) {
  //Check out mrexcel.com
  return (
    <Fragment>
      <BlogList
        children={children}
        pageNode={pageNode}
        location={location}
        posts={posts}
        {...props}
      />
    </Fragment>
  )
}
