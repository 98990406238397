import { title } from "assets/jss/common/threemysticapes.js";

const contactPageStyle = {
  title: {
    ...title,
    textAlign: "center"
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
};

export default contactPageStyle;
