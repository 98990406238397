const slugify = require(`slugify`)
const { useState } = require(`react`)
const validate = require(`validate.js`)

module.exports = {
  CreateStateData: function (value) {
    const [data, setData] = useState(value)

    return { data: data, setData: setData }
  },

  GetMinMaxError: function (data, minCharacters, maxCharacters) {
    if (!data) {
      return ""
    }

    if (data.length < minCharacters && minCharacters > 0) {
      return " (min " + minCharacters + " characters)"
    }

    if (data.length >= maxCharacters && maxCharacters > 0) {
      return " (max " + minCharacters + " characters)"
    }

    return ""
  },
  GeneralFormObject: function (
    errorText,
    minCharacters,
    maxCharacters,
    validateJS
  ) {
    const _self = this
    return {
      props: {
        error: _self.CreateStateData(false),
        helperText: _self.CreateStateData(""),
      },
      value: _self.CreateStateData(""),
      validate: function () {
        if (!this.value.data) {
          this.props.error.setData(true)
          this.props.helperText.setData(
            errorText +
              _self.GetMinMaxError(
                this.value.data,
                minCharacters,
                maxCharacters
              )
          )
          return false
        }

        const value = this.value.data.toString().trim()

        const isValid =
          value !== "" &&
          (value.length >= minCharacters || minCharacters === 0) &&
          (value.length <= maxCharacters || maxCharacters === 0)
        const validationResult =
          typeof validateJS === "undefined" || validateJS === null
            ? undefined
            : validate({ data: this.value.data }, validateJS)

        if (!isValid || typeof validationResult !== "undefined") {
          this.props.error.setData(true)
          this.props.helperText.setData(
            errorText +
              _self.GetMinMaxError(
                this.value.data,
                minCharacters,
                maxCharacters
              )
          )

          return false
        }

        this.props.error.setData(false)
        this.props.helperText.setData("")

        return true
      },
    }
  },
  GetSeriesName: function (source) {
    if (
      source.post.seriesData === null ||
      typeof source.post.seriesData === "undefined"
    ) {
      return null
    }
    if (
      source.post.seriesData.series === null ||
      typeof source.post.seriesData.series === "undefined"
    ) {
      return null
    }
    const seriesType = (typeof source.post.seriesData.series).toLowerCase()

    if (seriesType === "string") {
      return source.post.seriesData.series
    }

    return source.post.seriesData.series.name
  },
  PostSlugGenerator: function ({ postNode, args, context }) {
    const startDate = this.GetDateTime(postNode.post.startDate)
    if (startDate === null) {
      return null
    }
    if (isNaN(startDate.getTime())) {
      return null
    }

    let slug =
      "/blog/" +
      startDate.getFullYear() +
      "/" +
      (startDate.getMonth() + 1) +
      "/"

    if (
      postNode.post.seriesData !== null &&
      typeof postNode.post.seriesData !== "undefined"
    ) {
      slug +=
        this.GetSeriesName(postNode) +
        "/Part-" +
        postNode.post.seriesData.part +
        "/"
    }
    slug += postNode.post.title

    return this.Slugify(slug).toLowerCase()
  },
  escapeRegExp: function (str) {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&")
  },
  TrimURLPath: function (strValue) {
    const trimCharacters = "/\\\\"
    return this.TrimEnd(strValue, trimCharacters)
  },

  TrimURLPathBoth: function (strValue) {
    const trimCharacters = "/\\\\"
    return this.TrimStart(
      this.TrimEnd(strValue, trimCharacters),
      trimCharacters
    )
  },
  Trim: function (strValue, trimCharacters) {
    return this.TrimEnd(
      this.TrimStart(strValue, trimCharacters),
      trimCharacters
    )
  },
  TrimStart: function (strValue, trimCharacters) {
    if (strValue === null || typeof strValue === "undefined") {
      return null
    }
    if (trimCharacters === null || typeof trimCharacters === "undefined") {
      trimCharacters = "\\s"
    }

    const trimEndRegEx = new RegExp("^[" + trimCharacters + "]+")

    return strValue.replace(trimEndRegEx, "")
  },
  TrimEnd: function (strValue, trimCharacters) {
    if (strValue === null || typeof strValue === "undefined") {
      return null
    }
    if (trimCharacters === null || typeof trimCharacters === "undefined") {
      trimCharacters = "\\s"
    }
    const trimEndRegEx = new RegExp("[" + trimCharacters + "]+$")

    return strValue.replace(trimEndRegEx, "")
  },
  Slugify: function (slugData, forceLowerCase) {
    if (slugData === null || typeof slugData === "undefined") {
      return ""
    }

    if ((typeof slugData).toLowerCase() !== "string") {
      return slugData.map(item => {
        return this.Slugify(item, forceLowerCase)
      })
    }

    if (
      forceLowerCase === null ||
      typeof forceLowerCase === "undefined" ||
      forceLowerCase === true
    ) {
      return slugify(slugData, {
        remove: /[^\w\s$*_+~.()'"!\-:@/]/g, // this is the set of allowable characters
      }).toLowerCase()
    }

    return slugify(slugData, {
      remove: /[^\w\s$*_+~.()'"!\-:@/]/g, // this is the set of allowable characters
    })
  },
  FormatShortDate: function (date) {
    return new Intl.DateTimeFormat("en-US", {
      timeZone: "UTC",
      hour12: true,
      month: "short",
      day: "numeric",
    }).format(date)
  },
  FormatDate: function (date) {
    return new Intl.DateTimeFormat("en-US", {
      timeZone: "UTC",
      hour12: true,
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date)
  },
  FormatDateTime: function (date) {
    return new Intl.DateTimeFormat("en-US", {
      timeZone: "UTC",
      hour12: true,
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
    }).format(date)
  },
  GetDateTime: function (dateString) {
    if (dateString === null || typeof dateString === "undefined") {
      return null
    }
    if (dateString.indexOf("T") < 0) {
      dateString += "T07:00:00Z"
    }

    const dateObject = new Date(dateString)

    if (isNaN(dateObject.getTime())) {
      return null
    }

    return dateObject
  },

  PostShowNode: function (postNode, args, context) {
    const nodeWithContext = context.nodeModel.findRootNodeAncestor(
      postNode,
      node => node.internal && node.internal.type === `File`
    )

    if (
      nodeWithContext.absolutePath.toLowerCase().indexOf("-samplestructure-") >
        -1 ||
      nodeWithContext.absolutePath.toLowerCase().indexOf("-archive-") > -1
    ) {
      return false
    }

    if (process.env.NODE_ENV.toLowerCase() === "development") {
      return true
    }

    const now = Date.now()
    const startDate = this.GetDateTime(postNode.post.startDate)
    const endDate = this.GetDateTime(postNode.post.endDate)

    if (startDate === null || endDate === null) {
      return false
    }
    if (startDate > now) {
      return false
    }

    if (startDate !== endDate && endDate > startDate && now > endDate) {
      return false
    }

    return true
  },
}
