import {
  primaryColor,
} from "assets/jss/common/threemysticapes.js";

const accordionStyle = {
  accordion:{
    "&.dark": {
      backgroundColor: primaryColor.normal.dark.background,
      color: primaryColor.normal.dark.textColor,
      "&.light": {
        backgroundColor: primaryColor.light.dark.background,
        color: primaryColor.normal.dark.textColor,
      }
    }
  },

};

export default accordionStyle;
